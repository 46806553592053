/*=========================================================================================
  File Name: moduleDiagnosisMutations.js
  Description: Diagnosis Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Diagnosis.unshift(item);
  },
  SET_Diagnosis(state, Diagnosis) {
    state.Diagnosis = Diagnosis;
  },
  UPDATE_Diagnosis(state, Diagnosis) {
    const DiagnosisIndex = state.Diagnosis.findIndex(p => p.ID == Diagnosis.ID);
    Object.assign(state.Diagnosis[DiagnosisIndex], Diagnosis);
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.Diagnosis.findIndex(p => p.ID == itemId);
    state.Diagnosis.splice(ItemIndex, 1);
  }
};
