/*=========================================================================================
  File Name: moduleDiagnosisActions.js
  Description: Diagnosis Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
  AddDiagnosis({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Diagnosis/AddDiagnosis", item)
        .then(response => {
          commit(
            "ADD_ITEM",
            Object.assign(item, { ID: response.data.Data.ID })
          );
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetAllDiagnosis({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/Diagnosis/GetAllDiagnosis")
        .then(response => {
          commit("SET_Diagnosis", response.data.Data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  UpdateDiagnosis({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/Diagnosis/UpdateDiagnosis", item)
        .then(response => {
          commit("UPDATE_Diagnosis", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetDiagnosis({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/Diagnosis/GetAllDiagnosis", itemid)
        .then(response => {
          commit("UPDATE_Diagnosis", response.data.Data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  DeleteDiagnosis({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/api/Diagnosis/DeleteDiagnosis?ID=" + item.ID)
        .then(response => {
          commit("REMOVE_ITEM", item.ID);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
